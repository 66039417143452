export default {
  name: "CustomersComponent",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      tableData: [],
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      page: 1,
      iTotalItems: 0,
      screenHeight: 0,
      screenWidth: 0,
      texts: "",
      tab: null,
    };
  },
  beforeMount() {
    this.$store.commit('setMenuSidebar', {active:false})
    this.$store.commit("setUserState", null);
    this.texts = FILE.customerTexts[this.selectLanguage];
  },
  mounted() {
    this.fillTable();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // entra al detalle del comprador seleccionado
    detailItem(id) {
      this.$router.push(`/admin/customer-detail/${id.sCustomerEnterpriseId}`);
    },
    // acciona el dialog para cambio de contrasena
    dialogPasswordChange(item) {
      this.$store.commit("setPasswordChange", {
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${item.sCustomerEnterpriseId}/password`,
        active: true,
      });
    },
    // consulta los compradores disponibles
    fillTable() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/customers/enterprises`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          bVerified: this.bUserState
          // tStart: this.startDate,
          // tEnd: this.endDate,
        },
      })
        .then((response) => {
          this.tableData = response.data.results.map((e) => {
            return {
              ...e,
              created_at: e.created_at.substr(0, 10),
            };
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
      if (window.innerWidth > 800) {
        this.screenWidth = window.innerWidth - 100;
      } else {
        this.screenWidth = window.innerWidth
      }
    },
  },
  computed: {
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    bUserState() {
      return this.$store.state.bUserState;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refreshTable: function () {
      this.fillTable();
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    bUserState() {
      this.iCurrentPage = 1;
      this.fillTable();
    },

    iCurrentPage: function () {
      this.fillTable();
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.customerTexts[this.selectLanguage];
        this.fillTable();
      }
    },
  },
};