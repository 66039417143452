var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-body"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-inside"},[(_vm.tableData.length == 0)?_c('empty-data',{attrs:{"screenHeight":_vm.screenHeight}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":true,"disable-sort":true,"headers":_vm.texts.table.headers,"items":_vm.tableData},scopedSlots:_vm._u([{key:"item.sEmail",fn:function(ref){
var item = ref.item;
return [(item.sEmail)?_c('span',[_vm._v(" "+_vm._s(item.sEmail)+" ")]):_c('span',[_vm._v(" \""+_vm._s(_vm.texts.informationNotYetRegistered)+"\" ")])]}},{key:"item.sPhoneNumber",fn:function(ref){
var item = ref.item;
return [(item.sPhoneNumber)?_c('span',[_vm._v(" "+_vm._s(item.sPhoneNumber)+" ")]):_c('span',[_vm._v(" \""+_vm._s(_vm.texts.informationNotYetRegistered)+"\" ")])]}},{key:"item.bBlockStatus",fn:function(ref){
var item = ref.item;
return [(item.bBlockStatus)?_c('span',{staticClass:"mon-bold f-s14 color47B900"},[_vm._v(_vm._s(_vm.texts.table.status.actives))]):_c('span',{staticClass:"mon-bold f-s14 colorD8686A"},[_vm._v(_vm._s(_vm.texts.table.status.inactives))])]}},{key:"item.bVerified",fn:function(ref){
var item = ref.item;
return [(item.bVerified)?_c('span',{staticClass:"mon-bold f-s14 color6FD4FF"},[_vm._v(" "+_vm._s(_vm.texts.table.status.verified)+" ")]):_c('span',{staticClass:"mon-bold f-s14 colorD8686A"},[_vm._v(" "+_vm._s(_vm.texts.table.status.Unverified)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-5",attrs:{"icon":"","color":"#000000"},on:{"click":function($event){return _vm.detailItem(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"icon":"","color":"#000000"},on:{"click":function($event){return _vm.dialogPasswordChange(item)}}},[_c('v-icon',[_vm._v("mdi-key")])],1)]}},{key:"footer",fn:function(){return [_c('hr'),_c('v-pagination',{staticClass:"mt-2  mon",attrs:{"color":"yellow darken-1","length":_vm.iNumPages},model:{value:(_vm.iCurrentPage),callback:function ($$v) {_vm.iCurrentPage=$$v},expression:"iCurrentPage"}})]},proxy:true}])})],1)]),_c('PoweredBy',{staticClass:" pa-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }